<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-button slot="start" href="/tabs/About">
          <ion-icon :icon="information"/>
	</ion-button>
        <ion-title><h1>File Processed Successfully</h1></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">File Processed Successfully</ion-title>
        </ion-toolbar>
      </ion-header>

  <ion-card>
    <ion-card-content>
      Your file was uploaded and modified successfully.
      Download the modified version <a :href="getPath()">here</a>.
    </ion-card-content>
  </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonCardContent, IonCard, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonIcon, IonButton } from '@ionic/vue';
import { information } from 'ionicons/icons';

export default defineComponent({
  name: 'SubmittedFilePage',
  components: { IonCardContent, IonCard, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonIcon, IonButton },
  setup() {
    return {
      information,
  } },
  methods:{
    getPath: function() {
      return "https://server.filetools.cloud/download/" + this.$route.query.id;
  } },
});
</script>
