import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import SubmittedFilePage from '../views/SubmittedFilePage.vue'
import SubmittedImagePage from '../views/SubmittedImagePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/About'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/About'
      },
      {
        path: 'ChangeFileTime',
        component: () => import('@/views/FileTimesPage.vue')
      },
      {
        path: 'ChangeExif',
        component: () => import('@/views/ExifPage.vue')
      },
      {
        path: 'About',
        component: () => import('@/views/AboutPage.vue')
      },
      {
        path: 'FAQ',
        component: () => import('@/views/FaqPage.vue')
      },
    ]
  },
  {
    path: '/submitted',
    component: TabsPage,
    children: [
      {
        path: 'image',
        component: SubmittedImagePage,
      },
      {
        path: 'file',
        component: SubmittedFilePage,
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
