<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="ChangeFileTime" href="/tabs/ChangeFileTime">
          <ion-icon :icon="save"/>
          <ion-label>Change File Times</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="ChangeExif" href="/tabs/ChangeExif">
          <ion-icon :icon="image" />
          <ion-label>Change Jpeg Exif</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="About" href="/tabs/About">
          <ion-icon :icon="information"/>
          <ion-label>About FileTools.Cloud</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { save, image, information } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      save,
      image,
      information,
    }
  }
});
</script>
